/* utils */
@import 'utils/colors.scss';
@import 'utils/mobile.scss';
@import 'utils/animate.scss';

.TitBox .left {
  .btnBox {
    display: flex;
    gap: 5px;
  }
}

@import './responsive.scss';

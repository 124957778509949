footer {
  background-color: #12233a;
  color: rgba(255, 255, 255, 0.5);
  width: 100%;
  float: left;
  padding: 80px 0;
}
footer .inner,
.layerPopup .inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
footer p {
  color: rgba(255, 255, 255, 0.5);
}
footer h2 {
  color: #fff;
  margin-bottom: 0.4em;
}
footer h2 a {
  color: #fff;
  float: left;
}
footer .inner .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
footer .inner .right {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.address ul {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.2em;
}
.address li {
  position: relative;
  padding-right: 1em;

  padding-left: 1em;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 2px;
    height: 14px;
    top: 50%;
    left: -0.5px;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.2);
  }
  &:first-of-type {
    padding-left: 0;
    border-left: 0;
    &:before {
      display: none;
      content: none;
    }
  }
  @media all and (max-width: 800px) {
    padding-left: 0;
    &:before {
      display: none;
      content: none;
    }
  }
}
.copyright {
  margin: 2em 0 0;
  width: 100%;
  float: left;
}
footer a {
  color: rgba(255, 255, 255, 0.5);
}
footer .right li {
  text-align: right;
  display: inline-block;
  margin-left: 1em;
}

/* utils */
@import 'utils/colors.scss';
@import 'utils/mobile.scss';
@import 'utils/animate.scss';

.feedbackWrap {
  display: flex;
  justify-content: space-between;
  gap: 40px;

  .feedBack {
    max-width: 584px;

    li {
      &:not(&:last-of-type) {
        margin-bottom: 40px;
      }
    }

    dt {
      margin: 28px 0 0;
      display: flex;

      img {
        width: 48px;
        height: 48px;
        margin-right: 8px;
      }

      span {
        font-weight: 700;
        em {
          display: block;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: rgba(18, 35, 58, 0.6);
          font-style: normal;
        }
      }
    }

    dd {
      padding: 28px 24px;
      border-radius: 24px;
      background-color: #fff;
      font-size: 1.4em;
      line-height: 1.5em;
      font-weight: 400;
      letter-spacing: -0.03em;
      color: rgba(18, 35, 58, 0.5);
    }
  }
}

.main01 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 80px;

  li {
    margin: 8px;

    &:last-child {
      margin-top: 120px;
    }
  }
}

.mainSync {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-block: 40px;
  gap: 8px;

  li {
    flex: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    max-width: 100px;
    height: 51.47px;
    border-radius: 75px;
    justify-content: center;
    // margin: 8px;

    > img {
      max-width: 100%;
      height: auto;
    }
  }
}

.sectionTitleWrap {
  h2 {
    margin-bottom: 70px;
  }
  .point {
    padding-bottom: 16px;
  }
}
.restaurant-title {
  br {
    display: none;
  }
}

.introBox {
  width: 100%;
  display: flex;
  gap: 16px;

  li {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    min-height: 352px;
    border-radius: 16px;
    border: 2px solid #ededed;
    padding: 32px 28px;
    box-sizing: border-box;

    img {
      max-width: 80px;
      margin-left: auto;
    }

    &:first-of-type {
      img {
        max-width: 100px;
      }
    }
  }
}

.easyUseProduct {
  &.inner {
    padding: 0;
  }
  section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 60px;

    &:not(&:first-of-type) {
      padding-top: 140px;
    }

    > img {
      max-width: 520px;
      font-size: 0;
    }

    dl {
      max-width: 440px;
    }
  }
}

.kakaoChannelWrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: min-content 1fr;
  justify-items: end;

  .sectionTitleWrap {
    width: -webkit-fill-available;

    h2 {
      margin-bottom: 0;
    }
  }

  .kakaoChannelPhoneWrap {
    clear: both;
    width: fit-content;
    position: relative;
    padding-right: calc(32.66px + 45px);
    margin-top: 30px;
    grid-area: 1 / 2 / span 2 / span 2;
  }

  .desc {
    max-width: 440px;
    width: 40vw;
    padding-top: 100px;
    li {
      float: none;

      dl {
        width: 100%;
        float: none;
        margin: 0;
      }
    }
  }
}

@import './responsive.scss';

/* utils */
@import 'utils/colors.scss';
@import 'utils/mobile.scss';
@import 'utils/animate.scss';

.newsPage {
  line-height: 1.6em;

  .borderBottom {
    border-bottom: 1px solid #eee;
  }

  .history {
    border-top: 1px solid #eee;
    padding: 2em 0;
    float: left;
    width: 100%;

    h1 {
      float: right;
    }

    li {
      padding-left: 1em;
      margin-bottom: 0.8em;
      line-height: 1.5em;

      &::before {
        content: '\2022';
        color: #2d50b8;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        font-size: 1em;
        margin-left: -1em;
      }
    }
  }

  .bigDec {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5em;
    padding: 3em 0;
    letter-spacing:-0.014em;
    color: rgba(18, 35, 58, 0.9);
  }
  .desc-about {
    font-size:18px;
    color: rgba(18, 35, 58, 0.9);
    line-height:1.8em;
  }

  .wrap .companyDesc {
    display: flex;
    gap: 3%;

    .companyImg {
      flex-grow: 1;
      min-width: 50%;
      line-height: 0;
    }

    .companyImg > img {
      width: 100%;
    }

    .companyExplan {
      box-sizing: border-box;
      font-size:18px;
      letter-spacing: -0.01em;
      font-weight:400;
      line-height:1.6em;
      p {
        color: rgba(18, 35, 58, 0.9);
      }
    }
  }
}

@import './responsive.scss';

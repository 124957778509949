/*2022.03.25 leewooju@secondspace.kr 작성자 이우주/주식회사 세컨드스페이스*/

@media all and (max-width: 1100px) {
  p,
  dd {
    color: rgb(18 35 58 / 60%);
  }

  header {
    padding-block: 14px;
  }

  header .right button {
    height: 2.4em;
    width: auto;
    min-height: 28px;
    padding: 0 1em;
    font-size: 12px;
    margin-right: 13px;
  }

  .sub {
    .TitBox .left {
      width: 100%;
    }

    &.corp {
      .corpPhoneList .corpMain {
        width: 90%;
      }
    }
  }

  .inner {
    padding: 0 6%;
  }

  .phone {
    max-width: 260px;
  }

  .phone_s {
    max-width: 200px;
    border-radius: 24px;
    border: 5px solid;
  }

  .phone_xs {
    border-radius: 24px;
    border: 5px solid;
  }

  .solution-items li {
    width: 100%;

    .item-con {
      width: 100%;
    }
  }

  .form .btnBox p {
    width: 60%;
  }

  .layerPopup {
    overflow: auto;

    .form .btnBox p {
      width: 100%;
    }
  }

  .para02 .paraBox {
    max-width: 40%;
  }

  .max80 {
    max-width: 100%;
  }

  .TitBox .right {
    width: 70%;
    margin-top: 80px;
  }

  footer .inner .right {
    width: 20%;
  }

  .newsItem {
    min-height: unset;
  }

  .newsCon {
    padding: 7%;
  }

  .newsPage {
    .wrap .companyDesc {
      flex-direction: column;

      .companyExplan {
        padding-top: 60px;
      }
    }
  }

  .solution-items {
    flex-direction: column;
  }

  .bookingLink {
    padding: 0;
  }

  .easyUseProduct section {
    padding: 0;
    gap: 40px;

    > img {
      max-width: 488px;
    }
  }

  .kakaoChannelWrap {
    .kakaoChannelPhoneWrap {
      padding-right: 56.34px;
    }
  }
}
@media all and (max-width: 920px) {
  h2 {
    font-size: 2.4em;
  }

  .col3box {
    flex-direction: column;
    width: 50%;
    margin-left: 25%;

    h2 {
      max-width: 100%;
    }

    img {
      width: 50%;
      margin: 20px 0 60px;
    }
  }

  .sub .corpPhoneList .corpMain li {
    img {
      width: 200px;
    }

    &::before {
      width: 32vw;
      height: 32vw;
      margin-left: -4vw;
    }
  }

  .tabletWrap {
    flex-direction: column;

    .tablet {
      width: 70%;
      max-width: 100%;
      box-sizing: border-box;
    }

    .box {
      width: 100%;
      padding-top: 60px;
    }
  }

  footer .inner {
    flex-direction: column;
  }

  footer .inner .right,
  .layerPopupWrap .inner .right {
    width: 100%;
    align-content: space-around;
    flex-direction: row;
    align-items: center;
    margin-top: 60px;
  }

  .form {
    .right {
      width: 100%;
    }

    .btnBox p {
      float: left;
      margin-left: 40px;
    }
  }

  .layerPopup .form .btnBox p {
    margin: 0;
  }

  .layerPopup .inner {
    justify-content: center;

    .leftTit,
    .right {
      width: 100%;
      max-width: 340px;
    }

    .right {
      flex-direction: column;
    }
  }
}
@media all and (max-width: 800px) {
  body {
    font-size: 14px;
  }

  p br,
  .noBr br {
    display: none;
  }

  h1 {
    font-size: 2.4em;
  }

  h2 {
    font-size: 2em;

    &.restaurant-title {
      br {
        display: block;
      }
    }
  }

  h3 {
    font-size: 1.25em;
  }

  h4 {
    font-size: 1.1em;
  }

  .TitBox {
    margin-top: 24vh;

    .right {
      width: 100%;
    }

    p {
      margin: 20px 0 40px;
    }

    .left .btnBox {
      flex-direction: column;
    }

    .setImg {
      max-height: fit-content;
      flex-direction: column;
    }
  }

  .col3box {
    width: 100%;
    margin: 0;
  }

  .phone,
  .phone_s {
    max-width: 90%;
  }

  .imgTxt {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 50%;
      max-width: 50%;
    }
  }

  .corp .corpServiceImg {
    width: 100%;
  }

  .imgTxt img.phoneImg {
    max-width: 130px;
    border: 3px solid #12233a;
    border-radius: 12px;
    filter: drop-shadow(-8px 4px 12px rgba(18, 35, 58, 0.15));
  }

  .imgTxt .box {
    width: 100%;
    padding-top: 60px;
  }

  .imgTxt.reverse {
    flex-direction: column;
    > img {
      width: 70%;
      max-width: 100%;
    }
  }

  .imgTxt .setImg:not(.managerReserv .setImg) {
    flex-direction: column;
  }

  .managerReserv {
    flex-direction: column;
    gap: 46px;
  }

  .phone {
    border: 5px solid #12233a;
    border-radius: 28px;
  }

  .phone_s {
    border-radius: 20px;
  }

  .parallax {
    justify-content: flex-start;
    width: 100%;
    margin: 0;
  }

  .para01 .paraBox,
  .para02 .paraBox,
  .para03 .paraBox,
  .para04 .paraBox {
    margin: 42% 0 5vh;
    padding: 28px 24px;
    border-radius: 0px 8px 8px 8px;
  }

  .para01 .paraBox,
  .para01 .paraBox ul.box li:last-of-type p {
    margin-bottom: 0;
  }

  .form .btnBox {
    .defBtn {
      width: 100%;
    }
    p {
      width: 100%;
      margin: 20px 0 0 0;
    }
  }

  .main01 {
    flex-direction: column;
    width: 90%;
    padding: 0 5%;

    li:last-child {
      margin: 0;
    }
  }

  .address ul {
    flex-direction: column;
  }

  .paraBox h2 {
    font-size: 1.6em;
    line-height: 1.5em;
  }

  .sub .corpPhoneList .corpMain {
    flex-direction: column;

    li {
      margin-bottom: 60px;

      &::before {
        width: 340px;
        height: 340px;
        margin-left: -68px;
        margin-top: 50px;
      }
    }
  }

  .inner .right,
  ul.box dl {
    width: 100%;
  }

  .partnersWrap #partner {
    height: 40px;

    img {
      margin: 0 20px;
    }
  }

  .winner {
    width: 100%;
  }

  .winDec {
    flex-direction: column;

    li {
      width: 100%;
      margin-bottom: 40px;
    }
  }

  .parallax .paraBox ul.box li {
    width: 100%;
  }

  .parallax.para01 {
    height: auto;
  }

  .feedbackWrap {
    flex-direction: column;
    gap: 28px;

    .customer-review-title {
      margin-bottom: 0;
    }

    h2 {
      margin-bottom: 0 !important;
    }
    .feedBack {
      max-width: 100%;

      dl {
        width: 100%;

        dd {
          border-radius: 8px;
          font-size: 1.2em;
        }

        dt img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  .solution-items li {
    min-width: auto;

    a {
      height: 42px;
    }
  }

  .gapDef {
    height: 140px;
  }

  header {
    padding: 14px 20px;
    display: flex;
    align-items: center;

    img {
      height: 1.5em;
    }

    ul ul {
      width: 100%;
    }

    .right {
      z-index: 101;
      display: flex;
      align-items: flex-end;

      .mNav {
        display: block;
      }
    }

    .nav {
      background-color: #2d50b8;
      color: #fff;
      position: fixed;
      z-index: 99;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      // display: none;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s;

      ul {
        height: 100%;
        background-color: transparent;
        position: relative;

        a {
          color: #fff;
          padding: 0.4em 0;
          font-size: 2em;
        }
      }

      nav {
        display: none;
        display: flex;
        align-content: center;
        height: 100%;

        > ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          > li {
            text-align: left;
            font-size: 0.9em;
            margin-bottom: 40px;
            color: rgba(255, 255, 255, 0.5);
            width: 240px;
          }
        }

        li:hover ul {
          border: none;
          padding: 0;
          height: auto;
        }
      }

      &.active {
        visibility: visible;
        opacity: 1;
        z-index: auto;
      }
    }
  }

  .newsList {
    flex-direction: column;

    li {
      width: 100%;
    }
  }

  .col2 {
    flex-direction: column;
  }

  .inner .col2 div {
    width: 100%;
  }

  .aboutCount {
    ul {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;

      > li {
        width: 100%;
        margin: 1em 0;
      }
    }

    h1 {
      margin-right: 0.4em;
    }

    li > h3 {
      font-size: 1em;
      letter-spacing: -0.05em;
    }
  }

  .newsPage {
    font-size: 1em;

    .history {
      display: flex;
      flex-direction: column;

      h1 {
        margin-bottom: 0.4em;
      }

      ul li br {
        display: none;
      }
    }

    .bigDec {
      font-size: 1.6em;
    }
  }

  .newsTap li {
    font-size: 1.2em;
  }

  .w50 {
    max-width: 100%;
  }

  .TitImg {
    height: calc(100vh - 193px - 24vh - 40px);
  }

  .main01 .phone {
    border-radius: 2.4em;
  }

  .tablet {
    border-radius: 18px;
  }

  .detailPage {
    width: 90%;
    margin: 5%;

    p {
      font-size: 16px;
      line-height: 1.6em;
    }

    br {
      display: block;
    }

    .subTxt {
      flex-direction: column;

      h4 {
        font-size: 1.4em;
        margin-top: 1em;
      }

      li:first-of-type,
      .writer {
        width: 100%;
      }

      .writer a {
        width: 100%;
        display: inline-block;
      }
    }
  }

  .layerPopup {
    h3 {
      margin: 1em 0;
    }

    .inner {
      flex-direction: column;
      justify-content: flex-start;
      margin: 10vh 0;

      .right {
        margin-top: 2em;
      }
    }

    .closeBtn {
      top: 20px;
      right: 20px;
    }
  }

  footer {
    li,
    p {
      font-size: 0.8em;
    }

    .logo img {
      height: 26px;
    }
  }

  .parallax {
    background-size: contain;
    border-radius: 0;
    background-attachment: unset;
    background-position: top;
    min-height: 30vh;
    height: auto;
  }

  // .setImg {
  //   flex-direction: column;
  // }

  .setImg .phone_xs,
  .TitBox .setImg .phone_xs {
    width: 100%;
    margin-left: 0;
    border-radius: 2em;
    max-width: 200px;
  }

  .setImg .tablet,
  .TitBox .setImg .tablet {
    width: 100%;
    border-radius: 20px;
    margin-bottom: 1em;
    max-width: 100%;
  }

  .introBox {
    width: 100%;
    flex-direction: column;
    gap: 28px;
    li {
      min-height: 280px;
      flex: auto;
      padding: 24px 21px;
    }
  }

  .bookingLink {
    padding-inline: 22px;
  }

  .aboutCount ul {
    // padding-inline: 24px;
    margin: 0;
    max-width: max-content;
    align-items: flex-start;
    justify-content: space-between;
  }

  .easyUseProduct section {
    flex-direction: column;
    align-items: center;
    gap: 28px;

    & > dl,
    & > img {
      max-width: 100%;
    }

    &:last-of-type {
      flex-direction: column-reverse;
    }

    &:not(.easyUseProduct section:first-of-type) {
      padding-top: 40px;
    }
  }

  .corp .corpImgTxt {
    flex-direction: column;
    > img {
      width: 100%;
    }
  }

  .kakaoChannelWrap {
    justify-items: center;
    grid-template-columns: 1fr;

    .desc {
      width: 100%;
      order: 3;
    }
    .kakaoChannelPhoneWrap {
      grid-area: unset;

      .kakaoChannelPhone {
        max-width: 166px;
        filter: drop-shadow(-4px 30px 30px rgba(18, 35, 58, 0.1)) drop-shadow(-8px 4px 12px rgba(18, 35, 58, 0.15));
        border-radius: 16px;
      }
      .subPhone {
        max-width: 190px;
        left: 27px;
        top: 28px;
        filter: drop-shadow(-4px 30px 30px rgba(18, 35, 58, 0.1)) drop-shadow(-8px 4px 12px rgba(18, 35, 58, 0.15));
        border-radius: 16px;
      }
    }
  }
}

@media all and (min-width: 800px) {
  header div.nav {
    display: block !important;
  }
}
@media all and (min-width: 1100px) {
}

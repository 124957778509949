.phone {
  border-radius: 40px;
  max-width: 320px;
  border: 7px solid #12233a;
  box-shadow: 6px 12px 32px rgb(0 0 0 / 12%);
}
.phone_s {
  border-radius: 40px;
  max-width: 300px;
  border: 7px solid #12233a;
  box-shadow: 6px 12px 32px rgb(0 0 0 / 12%);
}
.phone_xs {
  border-radius: 40px;
  max-width: 260px;
  border: 7px solid #12233a;
  box-shadow: 6px 12px 32px rgb(0 0 0 / 12%);
}
.tablet {
  border-radius: 30px;
  max-width: 47.2727%;
  border: 7px solid #12233a;
  box-shadow: 6px 12px 32px rgb(0 0 0 / 12%);
}
.phoneImg {
  max-width: 260px;
  border: 6px solid #12233a;
  filter: drop-shadow(-4px 30px 30px rgba(18, 35, 58, 0.1)) drop-shadow(-8px 4px 12px rgba(18, 35, 58, 0.15));
  border-radius: 24px;
  box-sizing: border-box;
}
.frontPhone {
  position: relative;
  top: 46px;
  margin-left: -39px;
  z-index: 1;
}
.kakaoChannelPhone {
  max-width: 300px;
  display: block;
}
.subPhone {
  position: absolute;
  max-width: 307px;
  left: 45px;
  top: 43.86px;
  filter: drop-shadow(-8px 30px 40px rgba(18, 35, 58, 0.15));
}

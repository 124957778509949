/* utils */
@import 'utils/colors.scss';
@import 'utils/mobile.scss';
@import 'utils/animate.scss';

.newsTap li:last-child {
  margin: 0;
}

// empty category
.emptyCategoryWrap {
  text-align: center;
  padding-top: 10%;
  > h1 {
    font-size: 70px;
  }
  > p {
    font-size: 20px;
    padding-top: 25px;
  }
}

@import './responsive.scss';

/* utils */
@import '../utils/colors.scss';
@import '../utils/mobile.scss';
@import '../utils/animate.scss';

/* about, index */
.aboutCount {
  font-size: 1.4em;
  font-weight: 700;

  // ul {
  //   display: flex;
  //   width: 822px;
  //   margin: 0 auto;
  //   flex-direction: row;
  //   flex-wrap: wrap;
  //   align-items: center;
  //   justify-content: space-between;

  //   > li {
  //     width: 100%;
  //     display: flex;
  //     align-items: center;
  //   }
  // }
  ul {
    // border-top: 1px solid #eee;
    display: flex;
    max-width: 822px;
    margin: 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 1em;
    margin-top: 2em;

    > li {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  h2 {
    display: flex;
    margin: 0 0.6em 0 0;

    span {
      font-size: 2em;
      margin-right: 0.1em;
    }
  }

  h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.01em;
    color: rgba(18, 35, 58, 0.6);
    padding-bottom: 4px;
  }

  .countWrap {
    width: 100%;
    display: flex;

    h3 {
      font-weight: 700;
      font-size: 35.84px;
      line-height: 42px;
    }
  }
}

/* utils */
@import 'utils/colors.scss';
@import 'utils/mobile.scss';
@import 'utils/animate.scss';

.col3box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  width: 100%;

  h2 {
    max-width: 280px;
  }

  dl {
    width: 100%;
  }

  img {
    margin: 0 60px;
  }
}

.restaurantService {
  > img.phone_s:not(& > img.phone_s:first-of-type) {
    margin-top: 60px;
  }
}

.tablet {
  max-width: 520px;
}

@import './responsive.scss';

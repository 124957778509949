.inputWrap {
  padding: 0;
  float: none;
  margin-bottom: 8px;
  display: flex;
  width: 100%;
  background: #fff;

  > label {
    padding-block: 10.5px;
    line-height: 21px;
    position: unset;
    margin-left: 16px;
    font-weight: 700;
    padding-right: 10px;
  }

  .title-label {
    line-height: 21px;
    flex: none;
  }

  input {
    &:focus {
      outline: none;
    }
    font-size: 1em;
    font-weight: 500;
    float: none;
    flex: 1;
    width: 100%;
    border: none;
    transition: 0.2s ease;
    text-indent: 0px;
    -webkit-ime-mode: active;
    -moz-ime-mode: active;
    -ms-ime-mode: active;
    ime-mode: active;
  }
}

.form {
  display: flex;
  justify-content: space-between;
  column-gap: 100px;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  box-sizing: border-box;

  .right {
    width: 83%;
    max-width: 601px;
    float: none !important;
  }

  .left {
    max-width: 400px;
    float: none !important;
  }

  .btnBox {
    margin-top: 20px;
    float: left;
    width: 100%;

    a {
      font-weight: 700;
      text-decoration: underline;
      color: #12233a;
    }

    .defBtn {
      float: left;
    }

    p {
      float: right;
      width: 410px;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
    }
  }

  .required > label::after {
    content: '*';
    color: #ee4b55;
    font-weight: 900;
    margin-left: 2px;
  }
}

header {
  position: -webkit-sticky;
  position: sticky;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #fff;
  padding: 17px 20px;
  box-sizing: border-box;
  font-weight: 700;
  z-index: 10;
  top: 0px;
  left: 0;
}
header .nav {
  text-align: center;
}
header nav li {
  display: inline-block;
  padding: 9px 35px;
  cursor: pointer;
}

header nav > ul > li {
  font-size: 18px;
}
header nav > ul > li > ul > li {
  font-size: 15px;
}

@media all and (min-width: 800px) {
  header nav > ul > li:first-of-type {
    padding-left: 5px;
  }
  header nav > ul > li:last-of-type {
    padding-right: 5px;
  }
}

header nav li:hover ul {
  height: fit-content;
  padding: 0.8em 0;
  border: 1px solid #eee;
}
header nav li ul li a {
  padding: 8px 20px;
  width: 100%;
  float: left;
  cursor: pointer;
  color: #12233a;
}
header ul ul {
  height: 0;
  overflow: hidden;
  width: 160px;
  padding: 0;
  position: absolute;
  transition: 0.4s ease;
  border: 0 solid #eee;
  margin-top: 12px;
  background-color: #fff;
}
header ul ul li {
  display: block;
  float: left;
  text-align: left;
  padding: 0;
  width: 100%;
}
header .left a span {
  font-size: 0;
  color: transparent;
}
header .left img {
  float: left;
  &.cross {
    height: 11px;
    margin: 12px 5px 5px 5px;
    visibility: hidden;
  }
  &.kakaoLogo {
    margin: 6px 0 5px 2px;
    visibility: hidden;
  }
  @media all and (max-width: 800px) {
    &.cross,
    &.kakaoLogo {
      zoom: 0.75;
    }
  }
}
header .left.withkakao img {
  visibility: unset;
}
header .btn-start {
  min-height: 36px;
  padding: 8px 12px 9px;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
}

/* mobile Nav */
.mNav {
  width: 24px;
  height: 24px;
  display: none;
}
.mNav li {
  width: 24px;
  height: 2px;
  background-color: #12233a;
  position: absolute;
  transition: 0.2s ease;
}
.mNav li:nth-child(2) {
  margin-top: 8px;
}
.mNav li:nth-child(3) {
  margin-top: 16px;
}
.mNav.active li {
  background-color: #fff;
}
.mNav.active li:first-of-type {
  transform: rotate(45deg);
  margin-top: 8px;
}
.mNav.active li:nth-child(2) {
  width: 0;
  margin-top: 8px;
}
.mNav.active li:nth-child(3) {
  margin-top: 8px;
  transform: rotate(-45deg);
}

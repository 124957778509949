/* utils */
@import 'utils/colors.scss';
@import 'utils/mobile.scss';
@import 'utils/animate.scss';

.detailPage {
  width: 640px;
  margin: 0 auto;

  p {
    word-break: break-all;
  }

  h4 {
    margin-bottom: 0.4em;
  }

  img {
    max-width: 100%;
  }

  .detailImg {
    margin-bottom: 60px;
    text-align: center;

    .hideImg {
      display: none;
    }
  }

  .textArea {
    word-break: normal;
  }

  .subTxt {
    display: flex;
    width: 100%;
    font-size: 0.9em;
    margin-top: 160px;
    flex-direction: row;
    justify-content: space-between;

    li:first-of-type {
      width: 65%;
    }

    .tablemanagerSummary {
      word-break: break-all;
    }

    .writer {
      width: 25%;

      .contact a {
        display: block;
      }
    }
  }

  .snsLinks {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: center;

    a {
      padding: 0.4em;
      margin: 0.4em;

      img {
        width: 1.8em;
        height: 1.8em;
        opacity: 0.5;
        transition: 0.2s ease;
      }

      &:hover img {
        opacity: 1;
      }
    }
  }
}

@import './responsive.scss';

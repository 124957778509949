.TitBox {
  width: 100%;
  margin-top: 300px;
  margin-bottom: 40px;

  p {
    font-weight: 400;
    margin: 40px 0 80px;
  }
}

.TitImg {
  background-image: url(/assets/images/main_vis.jpg);
  background-size: cover;
  background-position: 50% 50%;
  width: 100%;
  height: 840px;
  float: left;
}

.leftTit {
  max-width: 320px;
}

ul.box {
  float: right;
  width: 100%;

  li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    padding: 40px 0;
    float: left;
    width: 100%;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-child {
      border: 0;
      padding-bottom: 0;
    }
  }

  dl {
    float: left;
    width: 70%;
    margin-right: 15%;
  }

  img {
    float: right;
    width: 15%;
  }
}

/* sub */
.sub {
  .TitBox {
    h4 {
      color: #2d50b8;
      margin-bottom: 0.8em;
    }

    .left {
      width: 50%;
    }
  }
}

.TitBox {
  .right {
    width: 50%;

    img {
      float: right;
      // width: 100%;
    }
  }

  .setImg {
    max-height: 400px;
    width: 100%;

    .phone_xs {
      margin-left: -29%;
      max-width: 200px;
    }
  }
}

.colorBack {
	background-color: rgba(45, 80, 184, 0.1);
}
.yellow {
	background-color: #fece38;
}
.white-blue {
	background-color: #cddde6;
}
.blue {
	background-color: #2d50b8;
	color: #fff;
}
.point {
	color: #2d50b8;
}

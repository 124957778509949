/* utils */
@import '../utils/colors.scss';
@import '../utils/mobile.scss';
@import '../utils/animate.scss';

/* newslist, presslist */
.paging {
  width: 300px;
  display: flex;
  justify-content: space-around;
  margin: 40px auto;
  color: rgba(18, 35, 58, 0.4);

  li {
    display: inline-block;

    a {
      color: rgba(18, 35, 58, 0.2);
      font-weight: 700;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border-radius: 30px;
      transition: 0.2s ease;

      &:hover {
        background-color: rgba(187, 207, 235, 0.4);
      }
    }

    &.active a {
      color: #2d50b8;
    }
  }
}

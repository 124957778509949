.solution-items {
  display: flex;

  gap: 22px;

  > li {
    display: flex;
    flex-direction: column;
    width: 32%;
    min-width: 352px;
    min-height: 360px;
    flex: 1;
    // float: left;
    box-sizing: border-box;
    padding: 32px 28px;

    border: 4px solid #12233a;
    box-shadow: -8px 30px 40px rgba(18, 35, 58, 0.15);
    border-radius: 16px;

    .item-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > span {
        font-weight: 500;
        font-size: 14px;
      }
    }

    p {
      width: 100%;
      margin-top: 8px;
      // float: left;
    }

    &:last-child {
      // float: right;
      margin-right: 0;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 48px;
      background-color: #12233a;
      color: #fff;
      // float: left;
      text-align: center;
      border-radius: 5px;
      // margin-top: 3em;
    }

    span {
      // float: right;
      border-radius: 30px;
      padding: 2px 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      max-height: 27px;
    }

    .item-con {
      display: flex;
      flex: auto;
      flex-direction: column;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding-top: 1.5em;
      max-height: 100%;
      justify-content: space-between;
      gap: 24px;

      li {
        border: none;
        display: block;
        width: 100%;
        padding: 0;

        &:not(&:first-of-type) {
          margin-top: 8px;
        }

        &::before {
          content: url('/assets/images/icon/checked_icon.svg');
          display: inline-block;
          padding-inline: 2px;
          margin-right: 10px;
        }
      }
      a {
        letter-spacing: 0.02em;
        font-size: 17px;
        font-weight: 500;
      }
    }
  }
}

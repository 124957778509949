.defBtn {
  padding: 0.8em 4em;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  transition: 0.2s ease;
}
button:hover,
.btn-more:hover {
  background-color: #2d60b8;
}
button:active,
.btn-more:active {
  background-color: #254e94;
}
.doneBtn {
  background-color: #2d50b8;
}
.btn-more {
  transition: 0.2s ease;
}
.onPopupBtn {
  font-weight: 500;
  font-size: 18px;
}

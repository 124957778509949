/* header, footer */
.layerPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(187, 207, 235, 0.4);
  backdrop-filter: blur(80px);
  color: #12233a;
  overflow: auto;
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;

  h3 {
    font-weight: 600;
    margin: 1em 0 3em;
  }

  li {
    display: flex;
    margin-bottom: 0.4em;
    font-size: 1rem;

    span {
      width: 10%;
      max-width: 30px;
      color: #2d50b8;
      font-weight: 600;
    }

    p {
      width: 90%;
      margin-bottom: 0;
    }
  }

  .inputWrap {
    box-shadow: 3px 6px 24px rgba(0, 0, 0, 0.1);
    input {
      border-radius: 3px;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  .btnBox {
    p {
      width: 100%;

      a {
        float: none;
      }
    }

    button {
      width: 100%;
      margin-bottom: 1em;
    }
  }

  .inner {
    display: flex;
    align-items: center;
    align-content: center;
    height: 100%;

    h2,
    p {
      color: #12233a;
    }

    .leftTit {
      max-width: 100%;
      width: 50%;
    }

    .right {
      width: 50%;
    }
  }

  .closeBtn {
    position: fixed;
    top: 5%;
    right: 5%;
    cursor: pointer;
  }
}

.layerPopup.active {
  visibility: visible;
  opacity: 1;
  z-index: 50;
}

.partnersWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 60px 0 70px;
  padding: 0;
  overflow: hidden;

  #partner {
    height: 60px;
    left: 0;
    width: fit-content;
    position: relative;
    white-space: nowrap;
    animation-name: scroll;
    animation-duration: 40s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    display: flex;
    padding: 0;
    overflow: hidden;

    img {
      height: 100%;
      margin: 0 40px;
    }
  }
}

/* utils */
@import 'utils/colors.scss';
@import 'utils/mobile.scss';
@import 'utils/animate.scss';

.corp {
  .serif {
    width: 30px;
    margin: 2em 0 1em;
  }

  #partner {
    animation-duration: 20s;
    height: 48px;
  }

  .corpPhoneList {
    position: relative;
    overflow: hidden;
    width: 100%;

    .corpMain {
      width: 100%;
      display: flex;
      max-width: 1100px;
      margin: 0 auto;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      li {
        position: relative;

        h3 {
          text-align: center;
          margin-top: 1em;
        }

        &::before {
          content: '';
          display: block;
          width: 400px;
          height: 400px;
          position: absolute;
          background-color: rgba(45, 80, 184, 0.1);
          border-radius: 400px;
          margin-left: -60px;
          margin-top: 100px;
          z-index: -1;
        }

        img {
          border-radius: 28px;
          box-shadow: 6px 12px 32px rgb(0 0 0 / 20%);
        }
      }
    }
  }

  .corpImgTxt {
    > img {
      width: 50%;
    }
  }

  .corpServiceImg {
    width: 60%;
  }
}

@import './responsive.scss';

/*2022.03.25 leewooju@secondspace.kr 작성자 이우주/주식회사 세컨드스페이스*/
@import './reset.scss';
@import './font.scss';

a {
  transition: 0.2s ease;
  -webkit-tap-highlight-color: transparent;
}
a:hover {
  color: #2d50b8;
}
body {
  font-size: 16px;
  color: #12233a;
  word-break: keep-all;
  line-height: 1.5em;
  overflow-x: hidden;
  letter-spacing: -0.02em;
  font-weight: 400;
}
button {
  background-color: #12233a;
  color: #fff;
  font-weight: 700;
  transition: 0.2s ease;
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
h1 {
  font-size: 3.2em;
  line-height: 1.3em;
}
h2 {
  font-size: 2.6em;
  line-height: 1.3em;
  margin-bottom: 1.1em;
}
h3 {
  font-size: 1.5em;
  line-height: 1.4em;
}
h4 {
  font-size: 1.25em;
  line-height: 1.5em;
}
h5 {
  font-size: 1.1em;
}
h6 {
  font-size: 0.9em;
}
p {
  margin: 0 0 1.5em;
  padding: 0;
}
p,
dd {
  color: rgb(18 35 58 / 70%);
}
dl,
dt,
dd {
  padding: 0;
  margin: 0;
}
dt {
  margin-bottom: 12px;
}

/* wrap */
main .wrap {
  width: 100%;
  float: left;
}
.wrap .inner {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  box-sizing: border-box;
}

/* border */
.topBorder {
  border-top: 1px solid #eee;
}

.left {
  float: left;
}
.right {
  float: right;
}
.center {
  text-align: center;
}
.inner .right {
  width: 60%;
  float: right;
}

/* width */
.w50 {
  max-width: 50%;
}
.w100 {
  width: 100%;
}
.max80 {
  width: 100%;
  max-width: 80%;
}

.requestBox p {
  margin: 1em 0 2em;
  font-size: 1.1em;
}

/* gap */
.gapDef {
  width: 100%;
  // float: left;
  height: 200px;
  clear: both;
}
.gap60 {
  width: 100%;
  float: left;
  height: 60px;
}
.on-desktop {
  @media all and (max-width: 1100px) {
    display: none !important;
  }
}
.on-handheld {
  @media all and (min-width: 1101px) {
    display: none !important;
  }
}

.wrap.noFloat {
  float: none !important;
}

ol,
ul {
  list-style: none;
}

.parallax {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 90%;
  border-radius: 30px;
  margin-left: 5%;
  height: 900px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  .paraBox {
    background-color: #fff;
    border-radius: 30px;
    padding: 5% 5% 3%;
    width: 70%;
    max-width: 800px;
    float: right;
    margin: 5%;

    h4 {
      margin-bottom: 1em;
    }

    .box {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
    }

    ul.box li {
      width: 47%;
      padding: 0;
      border: none;

      p {
        margin: 0.5em 0 2.5em;
      }
    }
  }

  &.para01 {
    background-image: url(/assets/images/platform-story.jpg);
  }

  &.para02 {
    background-image: url(/assets/images/sub-main01@2x.jpg);

    .paraBox {
      max-width: 30%;

      h4 {
        margin-bottom: 1em;
      }

      .picReview {
        margin-top: 2em;
        float: left;
        width: 100%;
      }
    }
  }

  &.para03 {
    background-image: url(/assets/images/sub-story04@2x.jpg);
  }

  &.para04 {
    background-image: url(/assets/images/restaurant/rest-membership02@2x.jpg);
  }
}

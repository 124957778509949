/* utils */
@import '../utils/colors.scss';
@import '../utils/mobile.scss';
@import '../utils/animate.scss';

.toastContainer {
  position: fixed;
  z-index: 99;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  max-width: 300px;
  border-radius: 10px;
  box-shadow: 2px 3px 18px 0px rgba(0, 0, 0, 0.2);
  background-color: #fff;

  > p {
    padding: 5% 5%;
    box-sizing: border-box;
    margin: 0;
    font-weight: 900;
    text-align: center;
    color: #12233a;
  }
}

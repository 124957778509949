.newsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  li {
    display: flex;
    width: 32%;
    flex-direction: column;
    align-content: space-between;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 2em;
    margin-right: 2%;
    transition: 0.6s ease;

    &:nth-child(3n) {
      margin-right: 0;
    }

    &:hover .newsCon h3 {
      text-decoration:underline;
    }

    &:hover:not(li.emptyImg) .newsImg {
      &:focus,
      &::after {
        transform: scale(1.1);
      }
    }
  }

  .newsItem {
    display: flex;
    width: 100%;
    flex-direction: column;
    border: 1px solid #fff;
    border-radius: 20px;
    overflow: hidden;
    min-height: 424px;
    max-height: 424px;
    box-sizing: border-box;
    justify-content: space-between;
    box-shadow: 6px 12px 32px rgb(0 0 0 / 12%);
    cursor: pointer;

    .newsCon {
      padding: 30px 30px 40px;
      height: 50%;
      box-sizing:border-box;

      .cate {
        padding: 3px 6px;
        //background-color: #eff0f4;
        border-radius: 5px;
        color: #2d50b8;
        border: 2px solid #2d50b8;
        font-weight: 500;
        font-size: 0.875em;
        margin-bottom: 1em;
        display: inline-block;
        box-sizing:border-box;
      }

      h3 {
        margin: 0 0 0.6em;
        line-height: 1.4em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #333!important;
        max-height: 2.8em;
        min-height: 2.8em;
        transition: 0.2s ease;
        font-weight: 500;
        font-size: 22px;
      }

      h6 {
        margin-top: 20px;
        font-size: 0.8em;
        font-weight: 400;
        color: rgba(18, 35, 58, 0.4);
      }
    }

    .newsImg:not(.emptyImg .newsImg) {
      width: 100%;
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      height: 50%;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: inherit;
        background-size: cover;
        transform-origin: center;
        transition: transform 0.5s ease-in-out;
      }
    }

    .mediaKit {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      padding: 30px;
      box-sizing: border-box;

      img {
        height: 3.4em;
      }

      .defBtn {
        padding: 0.8em 2em;
      }
    }
  }

  li.emptyImg .newsImg {
    width: 100%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 200px;
    background-size: 50%;
  }

  .btn-all {
    align-self: flex-end;
    color: #2d50b8;
    font-weight: 700;
    margin-top: 1.5em;
    font-size:14px;

    svg {
      fill: #2d50b8;
      margin-left: 0.4em;
    }
  }
}

.newsTap {
  display: flex;
  margin-bottom: 60px;

  li {
    font-size: 1.6em;
    font-weight: 700;
    color: #ccc;
    margin-right: 2em;
    padding-bottom: 0.4em;
    border-bottom: 0 solid #2d50b8;
    transition: 0.2s ease;
    cursor: pointer;

    &.active,
    &:hover {
      color: #2d50b8;
      border-bottom: 4px solid;
    }
  }
}

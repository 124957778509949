.imgTxt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;
  width: 100%;
  margin-top: 2em;

  .box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    align-items: flex-start;
    width: 40%;

    dl {
      width: 100%;
    }
  }

  div.box {
    > h2 {
      width: 100%;
    }

    > h3 {
      margin-bottom: 1em;
    }
  }
}

.imgTxt.reverse {
  flex-direction: row-reverse;
}

.setImg {
  display: flex;
  align-items: center;
  justify-content: center;

  .phone_xs {
    position: relative;
    width: 30%;
    border: 5px solid;
    border-radius: 2em;
    margin-left: -20%;
    z-index: 1;
  }
  .tablet {
    width: 70%;
    border-radius: 20px;
  }
}

.managerReserv {
  align-items: center;
  gap: 30px;
}
